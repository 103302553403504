<template>
  <div class="dropdown" ref="dropdown" v-resize="checkLinkBoundingClientRect">
    <navigation-item-link :menu-item="menuItem"/>

    <div class="dropdown-content"
         :class="dropdownContentClasses"
         v-if="menuItem.children.length"
         :style="dropdownContentStyle">

      <div v-for="(group, index) in splittedChildren">
        <div class="sub1"
             v-for="(subMenuItem, subIndex) in group"
             :key="subMenuItem.id"
             :class="{ 'margin-top-15': subIndex && subMenuItem.children.length}"
        >
          <navigation-item-link :menu-item="subMenuItem"/>

          <div class="sub2" v-for="subSubMenuItem in subMenuItem.children" :key="subSubMenuItem.id">
            <navigation-item-link :menu-item="subSubMenuItem"/>
          </div>
        </div>

        <template v-if="categoryOrBrandMenuItem && index === (splittedChildren.length - 1)">
          <hr>
          <div class="sub1">
            <navigation-item-link :menu-item="menuItem">
              {{ menuItem.menu_itemable_type === 'Category' ? 'Shop' : 'Browse' }} All {{ menuItem.name }}
            </navigation-item-link>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import NavigationItemLink from "./NavigationItemLink"

export default {
  name: "NavigationItem",
  components: {NavigationItemLink},
  props: {
    menuItem: {
      type: Object
    },
    leftSide: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      linkBoundingClientRect: null
    }
  },
  computed: {
    categoryOrBrandMenuItem() {
      return this.menuItem.menu_itemable_type === 'Category' ||
          (this.menuItem.menu_itemable_type === null && this.menuItem.name === 'Brands')
    },
    dropdownContentStyle() {
      return {[this.leftSide ? 'left' : 'right']: `${this.dropdownContentOffset}px`}
    },
    dropdownContentOffset() {
      if (!this.linkBoundingClientRect) {
        return 0
      }
      const gridGap = 20
      const columnWidth = 200
      const paddingLeftRight = 58

      const dropdownContentWidth = columnWidth * this.columnCount +
          gridGap * (this.columnCount - 1) +
          paddingLeftRight

      let result = 0
      if (this.leftSide) {
        if (process.client) {
          result = window.innerWidth - (this.linkBoundingClientRect.x + dropdownContentWidth)
        } else {
          result = this.linkBoundingClientRect.x + this.linkBoundingClientRect.width - dropdownContentWidth
        }
      } else {
        result = this.linkBoundingClientRect.x + this.linkBoundingClientRect.width - dropdownContentWidth
      }
      return result < 0 ? result : 0
    },
    columnCount () {
      if (process.client) {
        return this.splittedChildren.length > 3 && window.innerWidth <= 1150 ? 3 : this.splittedChildren.length
      }

      return this.splittedChildren.length > 3 ? 3 : this.splittedChildren.length
    },
    dropdownContentClasses() {
      return {
        three: this.splittedChildren.length <= 3,
        five: this.splittedChildren.length > 3
      }
    },
    splittedChildren() {
      const itemsCount = this.menuItem.children.reduce((acc, menuItem) => acc + menuItem.children.length + 1, 0)
      const columns = itemsCount > 30 ? 5 : 3
      const avgItemsPerColumn = Math.ceil(itemsCount / columns)
      const result = [...Array(columns)].map(i => [])
      let resultIndex = 0
      let currentColumnCount = 0

      this.menuItem.children.forEach(menuItem => {
        const itemsLength = menuItem.children.length + 1
        if ((avgItemsPerColumn - currentColumnCount) <= 0) {
          resultIndex = Math.min(resultIndex + 1, columns - 1)
          currentColumnCount = 0
        }
        currentColumnCount += itemsLength
        result[resultIndex].push(menuItem)
      })

      return result.filter(group => group.length)
    }
  },
  mounted() {
    this.checkLinkBoundingClientRect()
  },
  methods: {
    checkLinkBoundingClientRect() {
      if (process.client) {
        this.linkBoundingClientRect = this.$refs.dropdown?.getBoundingClientRect()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
