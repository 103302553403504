<template>
    <div class="grid-header grid-navigation">
        <nav class="nav">
            <div class="section">
                <navigation-item v-for="menuItem in mainDesktopMenuItems" :key="menuItem.id" :menuItem="menuItem"/>
            </div>

            <div class="section main-right-menu">
                <navigation-item v-for="menuItem in mainRightDesktopMenuItems"
                                 :key="menuItem.id"
                                 :menuItem="menuItem"
                                 :leftSide="false"/>
            </div>
        </nav>
    </div>
</template>

<script>
    import NavigationItem from "./navigation/NavigationItem";

    export default {
        name: 'navigation',
        components: {
            NavigationItem
        },
        computed: {
            mainDesktopMenuItems () {
                const menu = this.$store.getters['menu/getMenu']('main_desktop')
                return (menu && menu.menu_items) || []
            },
            mainRightDesktopMenuItems () {
                const menu = this.$store.getters['menu/getMenu']('main_right_desktop')
                return (menu && menu.menu_items) || []
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '@/assets/stylesheets/variables.scss';

    ::v-deep .nav {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 70px;

        .section {

            &:first-child {
                align-self: center;
                justify-self: left;
                margin-left: -8px;
            }

            &:last-child {
                align-self: center;
                justify-self: right;
            }

            .dropdown {
                display: inline-block;
                position: relative;

                a {
                    display: inline-block;
                    text-decoration: none;
                    background-color: #fff;
                    padding: 12px 8px;
                    font-family: "MotivaSans Light", MotivaSans-Fallback;
                    font-size: 1.4rem;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.36;
                    letter-spacing: 0.34px;
                    text-align: left;
                    color: #707070;

                    &:visited, &:focus {
                        color: #707070;
                    }

                    &:hover {
                        color: #000;
                    }
                }


                & > a {
                    padding: 19px 17px;
                    font-family: MotivaSans, MotivaSans-Fallback;
                    font-size: 1.4rem;
                    line-height: initial;
                    color: $gunmetal;

                    &:visited, &:focus {
                        color: $gunmetal;
                    }

                    &:hover {
                        padding-bottom: 17px;
                        border-bottom: 2px solid $gunmetal;
                        background: $alabaster;
                    }

                    @media (max-width: 1680px) {
                        font-size: 1.3rem;
                        letter-spacing: .34px;
                        padding: 19px 10px;
                    }

                    @media (max-width: 1280px) {
                        font-size: 1.15rem;
                        padding: 19px 8px;
                        letter-spacing: -.2px;
                    }

                    @media (max-width: $tablet-max) {
                        font-size: 1.5rem;
                        padding: 19px 16px;
                    }
                }

                .dropdown-content {
                    position: absolute;
                    display: none;
                    z-index: 99990;
                    background: #F7F4F2;
                    padding: 22px 29px;

                    $base-left-padding: 2px;

                    .sub-base {
                        font-size: 1.1rem;


                    }

                    .sub1 {
                        font-family:  MotivaSans, MotivaSans-Fallback;
                        font-weight: 600;
                        line-height: 1.38;
                        font-size: 1.4rem;
                        text-transform: uppercase;
                        letter-spacing: 0.38px;
                        color: #2e2e2e;
                        background-color: #F7F4F2;

                        a {
                            text-transform: uppercase;
                            font-family: "MotivaSans Bold", MotivaSans-Fallback;
                        }

                    }
                    .sub2 {
                        a {
                            text-transform: none;
                            font-family:  MotivaSans, MotivaSans-Fallback;
                            padding: 4px 18px;
                        }
                    }
                    a {
                        background-color: #F7F4F2;
                    }
                    hr {
                        margin: 10px 0 10px 14px;
                        border-color: #F7F4F2;
                    }
                    .margin-top-15 {
                        margin-top: 15px;
                    }

                    .margin-top-30 {
                        margin-top: 30px;
                    }


                    @for $i from 2 through 4 {
                        .sub#{$i} {
                            font-weight: normal;
                            background-color: #F7F4F2;
                        }
                    }
                }

                &:hover {
                    .dropdown-content {
                        display: grid;
                        grid-gap: 20px;
                        $base-grid-width: 215px; // default value
                        grid-auto-columns: $base-grid-width;
                        grid-auto-flow: column;

                        &.five {
                            @media only screen and (max-width: 1400px) {
                                grid-template-columns: repeat(3, $base-grid-width);
                                grid-auto-flow: initial;
                            }
                        }
                    }
                }

            }

        }

        .main-right-menu {
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 17;

            &::before, &::after {
                background: #707070;
                content: '';
                display: block;
                position: absolute;
                height: 100%;
                width: calc(100% + 60px);
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;

                @media (max-width: 1680px) {
                  width: calc(100% + 30px);
                }

                @media (max-width: 1280px) {
                  width: calc(100% + 15px);
                }
            }

            &::before {
                right: 0;
            }

            &::after {
                left: 0;
            }

            .dropdown {
                z-index: 99990;

                & > a {
                    text-transform: uppercase;
                    background: $color-gray-dark;
                    color: $color-white !important;
                    font-weight: 400;

                    &:hover,
                    &[href="/products?search[sale][]=true"],
                    &[href="/products?search%5Bsale%5D%5B%5D=true"] {
                        // Fake bold to prevent shifting
                        background: $color-gray-dark;
                        border-bottom: 2px solid $color-white;
                    }

                    &[href="/products?search[sale][]=true"],
                    &[href="/products?search%5Bsale%5D%5B%5D=true"] {
                      border-bottom: 0;

                      &:hover {
                        border-bottom: 2px solid $color-white;
                      }
                    }
                }
            }
        }

        @media (max-width: 1280px) {
            grid-gap: 25px;
        }
    }
</style>
